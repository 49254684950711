<template>
  <v-container fill-height fluid>
    <div>
      <v-overlay v-model="loading">
        <v-progress-circular
          indeterminate
          size="40"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <div class="center-logo">
          <app-logo></app-logo>
        </div>
        <v-card flat class="rounded-xl">
          <v-toolbar flat class="d-flex justify-center pt-2">
            <v-card-title class="bold-text capitalize">
              Verify Your Email
            </v-card-title>
          </v-toolbar>
          <v-card-subtitle class="px-13">
            A verification link has been sent to {{ user.email }}, kindly visit
            your email to activate your account.
          </v-card-subtitle>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <div class="justify-center d-flex pb-4">
              <v-btn
                text
                class="rounded-xl px-7 py-5 primary capitalize"
                @click="resendVerificationLink"
              >
                Resend Verification Link
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import { mapGetters, mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  components: {
    AppLogo,
  },
  name: "VerifyEmail",
  data() {
    return {
      form: {},
      loading: false,
      toast: new ResponseHelper(),
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["resend_verification_link"]),
    async resendVerificationLink() {
      this.loading = true;
      try {
        const response = await this.resend_verification_link();
        this.toast.sendSuccess(response);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
  },
};
</script>

<style scoped></style>
